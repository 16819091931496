<template>
  <div id="options">
    <div class="container pt-4">
      <div v-if="$store.state.mode === 'pick'">
        <!-- Dataset Picker -->
        <DatasetPicker
          title="Select Dataset to Translate"
          stateDoc="dataset"
          stateGeoJson="datasetGeoJson"
          stateHeader="datasetHeader"
          stateLoading="datasetLoading"
          v-if="$store.state.farm.id && !$store.state.dataset.name"
        />
        <div v-if="$store.state.dataset.name">
          <p class="mb-1"><strong>Selected Dataset</strong></p>
          <!-- SELECTED DATASET -->
          <div
            class="list-group-item list-group-item-action mb-2"
            style="background-color:lightgreen;"
          >
            <p class="lead mb-1">{{ $store.state.dataset.name }}</p>
            <i
              class="fas fa-times-circle clickable"
              id="remove-dataset"
              @click="removeDataset('dataset')"
            ></i>
            <!-- LOADING DATASET SPINNER -->
            <div
              v-if="$store.state.datasetLoading"
              class="loading-dataset-spinner"
            >
              <RingLoader
                :color="'#61539b'"
                id="spinner"
              ></RingLoader>
            </div>
          </div>
          <p class="mb-1"><strong>Dataset Header</strong></p>
          <b-form-group class="mb-1" description="Select a header to translate.">
            <b-form-select
              v-model="datasetHeader"
              :options="$store.state.dataset.headers"
              @change="optionsChanged()"
            ></b-form-select>
          </b-form-group>
          <DatasetPicker
            title="Select Sample Points Dataset"
            stateDoc="samplesDataset"
            stateGeoJson="samplesDatasetGeoJson"
            stateHeader="samplesDatasetHeader"
            stateLoading="samplesDatasetLoading"
            v-if="!$store.state.samplesDataset.name"
          />
          <div v-if="$store.state.samplesDataset.name">
            <p class="mb-1"><strong>Selected Samples Dataset</strong></p>
            <!-- SELECTED SAMPLES DATASET -->
            <div
              class="list-group-item list-group-item-action mb-2"
              style="background-color:lightgreen;"
            >
              <p class="lead mb-1">{{ $store.state.samplesDataset.name }}</p>
              <i
                class="fas fa-times-circle clickable"
                id="remove-dataset"
                @click="removeDataset('samplesDataset')"
              ></i>
              <!-- LOADING DATASET SPINNER -->
              <div
                v-if="$store.state.sampleDatasetLoading"
                class="loading-dataset-spinner"
              >
                <RingLoader
                  :color="'#61539b'"
                  id="spinner"
                ></RingLoader>
              </div>
            </div>
            <p class="mb-1"><strong>Samples Dataset Header</strong></p>
            <b-form-group class="mb-1" description="Select a header to use for the translation.">
              <b-form-select
                v-model="samplesDatasetHeader"
                :options="$store.state.samplesDataset.headers"
                @change="optionsChanged()"
              ></b-form-select>
            </b-form-group>
          </div>
        </div><!-- End Dataset settings -->

        <!-- VIEW CORRELATION -->
        <Correlation
          class="mt-3"
          v-if="!hideCorrelation && $store.state.dataset.name && $store.state.datasetHeader && $store.state.samplesDataset.name && $store.state.samplesDatasetHeader"
        />

        <!-- TRANSLATE BUTTON -->
        <b-btn
          v-if="$store.state.correlation > 0.01 && !$store.state.runningTranslation || $store.state.correlation < -0.01 && !$store.state.runningTranslation"
          variant="success"
          @click="$store.dispatch('runTranslation')"
          class="mt-1 w-100"
        >Run Translation</b-btn>
        <b-btn
          v-if="$store.state.correlation > 0.01 && $store.state.runningTranslation || $store.state.correlation < -0.01 && $store.state.runningTranslation"
          variant="light"
          class="mt-1 w-100"
        ><i class="fas fa-sync fa-spin"></i> Running Translation...</b-btn>
      </div><!-- /v-if pick mode -->

      <div v-if="$store.state.mode === 'save'">
        <b-btn
          @click="$store.commit('setStateProperty', { property: 'mode', value: 'pick' })"
          class="btn-sm"
        >← Back</b-btn>
        <p class="mb-1 mt-2" v-if="$store.state.translationGeoJson.features">
          <span class="text-success">Success!</span><br>
          <strong>Save Translated Dataset</strong>
        </p>
        <!-- CHOOSE LOCATION -->
        <b-form-group
          label="New Dataset Name:"
          v-if="$store.state.translationGeoJson.features"
        >
          <b-form-input v-model="post.name"></b-form-input>
        </b-form-group>

        <FolderPicker v-if="$store.state.translationGeoJson.features" class="mb-2"/>

        <b-btn
          @click="$store.dispatch('download')"
          class="mr-1"
          v-if="$store.state.translationGeoJson.features"
        >Download</b-btn>
        <b-btn
          v-if="!$store.state.posting && $store.state.translationGeoJson.features"
          @click="$store.dispatch('post')"
          variant="success"
        >Add to myEV</b-btn>
        <b-btn
          v-if="$store.state.posting"
          variant="light"
        ><i class="fas fa-sync fa-spin"></i> Adding now...</b-btn>
      </div><!-- /v-if save mode -->
    </div><!-- /.container -->
  </div><!-- /#options -->
</template>

<script>
import Correlation from './Correlation'
import DatasetPicker from './DatasetPicker'
import FolderPicker from './FolderPicker'
import RingLoader from 'vue-spinner/src/GridLoader'

export default {
  data () {
    return {
      hideCorrelation: false
    }
  },
  components: {
    Correlation,
    DatasetPicker,
    RingLoader,
    FolderPicker
  },
  computed: {
    datasetHeader: {
      get () {
        return this.$store.state.datasetHeader
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'datasetHeader', value: value })
      }
    },
    samplesDatasetHeader: {
      get () {
        return this.$store.state.samplesDatasetHeader
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'samplesDatasetHeader', value: value })
      }
    },
    post: {
      get () {
        return this.$store.state.post
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'post', value: value })
      }
    }
  },
  methods: {
    optionsChanged () {
      this.hideCorrelation = true
      setTimeout(() => {
        this.hideCorrelation = false
      }, 10)
      this.$store.commit('setStateProperty', { property: 'translationGeoJson', value: {} })
    },
    removeDataset (stateVar) {
      this.$store.commit('setStateProperty', { property: stateVar, value: {} })
      const mapObject = this.$store.state.mapObject
      if (mapObject.getLayer(stateVar + 'Preview')) {
        mapObject.removeLayer(stateVar + 'Preview')
        mapObject.removeSource(stateVar + 'Preview')
      }
      if (mapObject.getLayer(stateVar + 'PreviewOutline')) {
        mapObject.removeLayer(stateVar + 'PreviewOutline')
        mapObject.removeSource(stateVar + 'PreviewOutline')
      }
      this.$store.commit('setStateProperty', { property: 'translationGeoJson', value: {} })
    }
  }
}
</script>

<style scoped>
#options {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 300px;
  z-index: 0;
  overflow-y: scroll;
  padding-bottom: 50px;
}

#remove-dataset {
  position: absolute;
  top: 10px;
  right: 10px;
}

.loading-dataset-spinner {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255,255,255,0.3);
  width: 100%;
  height: 100%;
}
.v-spinner {
  position: absolute;
  left: calc(50% - 30px);
  top: 16px;
}

@media (max-width: 600px) {
  #options {
    top: 60%;
    height: 40%;
    width: 100%;
  }
}
</style>
