<template>
  <div id="correlation">
    <canvas id="correlationChart" width="400" height="400"></canvas>
    <div
      v-if="$store.state.correlation"
      class="text-center"
    >
      <p class="m-0"><small>r = {{ $store.state.correlation.toFixed(2) }} &nbsp; y = {{ $store.state.slopeIntercept.m.toFixed(2) }}x + {{ $store.state.slopeIntercept.b.toFixed(2) }}</small></p>
      <p
        v-if="$store.state.correlation >= 0.8 || $store.state.correlation <= -0.8"
        class="text-success"
      >Strong correlation detected.</p>
      <p
        v-else-if="$store.state.correlation < 0.8 && $store.state.correlation >= 0.3 || $store.state.correlation <= -0.3 && $store.state.correlation > -0.8"
        class="text-warning"
      >Correlation detected.</p>
      <p
        v-else
        class="text-danger"
      >Poor correlation detected.</p>
    </div><!-- /v-if correlation -->
  </div><!-- /#correlation -->
</template>

<script>
import * as ss from 'simple-statistics'
import Kdbush from 'kdbush'
import geokdbush from 'geokdbush'
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

export default {
  data () {
    return {
      chartData: {
        datasets: [
          {
            type: 'scatter',
            label: 'Observations',
            data: [],
            backgroundColor: '#ffc107'
          },
          {
            type: 'scatter',
            label: 'Regression',
            data: [],
            backgroundColor: '#333333',
            showLine: true
          }
        ]
      }
    }
  },
  created () {
    this.$store.commit('setStateProperty', { property: 'correlation', value: false })
    setTimeout(() => {
      this.runCorrelation()
    }, 1000)
  },
  methods: {
    createChart () {
      const ctx = document.getElementById('correlationChart')
      const myChart = new Chart(ctx, { // eslint-disable-line
        data: this.chartData,
        options: {
          scales: {
            x: {
              type: 'linear',
              position: 'bottom'
            }
          }
        }
      })
    },
    runCorrelation () {
      const datasetPoints = this.$store.state.datasetGeoJson
      const datasetPointsIndex = new Kdbush(datasetPoints.features, (p) => p.geometry.coordinates[0], (p) => p.geometry.coordinates[1]) //
      const samplesHeader = this.$store.state.samplesDatasetHeader
      const datasetHeader = this.$store.state.datasetHeader
      this.$store.state.samplesDatasetGeoJson.features.forEach(sample => {
        var graphPoint = {
          x: false,
          y: false
        }
        const sampleValue = Number(sample.properties[samplesHeader])
        if (sampleValue) {
          graphPoint.x = sampleValue
        }
        const neighborhoodFeatures = geokdbush.around(datasetPointsIndex, sample.geometry.coordinates[0], sample.geometry.coordinates[1], Infinity, 0.0045)
        let neighborhoodValuesTotal = 0
        neighborhoodFeatures.forEach(neighborhoodFeature => {
          const neighborhoodFeatureValue = Number(neighborhoodFeature.properties[datasetHeader])
          neighborhoodValuesTotal = neighborhoodValuesTotal += neighborhoodFeatureValue
        })
        const neighborhoodMean = neighborhoodValuesTotal / neighborhoodFeatures.length
        graphPoint.y = neighborhoodMean
        if (graphPoint.x && graphPoint.y) {
          this.chartData.datasets[0].data.push(graphPoint)
        }
      })
      // Get x and y values in arrays and pairs into array of arrays
      const xVals = []
      const yVals = []
      const coordPairs = []
      this.chartData.datasets[0].data.forEach(dataItem => {
        xVals.push(dataItem.x)
        yVals.push(dataItem.y)
        const coordPair = [dataItem.x, dataItem.y]
        coordPairs.push(coordPair)
      })
      // Calculate the correlation
      const correlation = ss.sampleCorrelation(xVals, yVals)
      this.$store.commit('setStateProperty', { property: 'correlation', value: correlation })
      // Get Slope and Intercept
      const slopeIntercept = ss.linearRegression(coordPairs)
      this.$store.commit('setStateProperty', { property: 'slopeIntercept', value: slopeIntercept })
      // Prep chart data for the regression line
      const lowestX = Math.min(...xVals)
      const highestX = Math.max(...xVals)
      this.chartData.datasets[1].data = [
        {
          x: lowestX,
          y: (slopeIntercept.m * lowestX) + slopeIntercept.b
        },
        {
          x: highestX,
          y: (slopeIntercept.m * highestX) + slopeIntercept.b
        }
      ]
      this.createChart()
    }
  }
}
</script>
