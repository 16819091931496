import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions.js'
import mutations from './mutations.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    alert: {
      active: false,
      message: 'This is a basic alert',
      level: 'alert-info'
    },
    breadcrumbs: [
      {
        id: 'root',
        name: 'Root'
      }
    ],
    correlation: false,
    datasets: [],
    dataset: {},
    datasetGeoJson: {},
    datasetHeader: false,
    datasetLoading: false,
    farm: {},
    folders: [],
    folder: {
      id: 'root',
      name: 'Root'
    },
    mapObject: {},
    mode: 'pick',
    post: {
      name: 'Translation'
    },
    posting: false,
    runningTranslation: false,
    samplesDataset: {},
    samplesDatasetGeoJson: {},
    samplesDatasetHeader: false,
    samplesDatasetLoading: false,
    spinner: false,
    slopeIntercept: false,
    translationGeoJson: {}
  },
  mutations: mutations,
  actions: actions,
  modules: {}
})
