<template>
  <div id="dataset-picker" class="card p-1">
    <p class="text-center mb-1"><strong>{{ title }}</strong></p>
    <div
      class="mb-2"
    >
      <span
        v-for="(crumb, i) in breadcrumbs"
        v-bind:key="i"
      >
        <b-badge
          pill
          variant="primary"
          @click="launchFolder(crumb, true)"
          class="clickable"
        >{{ crumb.name }}</b-badge><span v-if="(i + 1) !== breadcrumbs.length"> / </span>
      </span>
    </div>
    <div class="card p-2 scroll">
      <!-- FOLDERS -->
      <div class="list-group mb-2 folders" v-if="!loadingFolders">
        <div
          v-for="(folder, i) in $store.state.folders"
          v-bind:key="i"
          class="list-container"
        >
          <a
            @click="launchFolder(folder)"
            class="list-group-item list-group-item-action clickable mb-2 folder"
          >
            <p class="lead mb-1"><i class="fas fa-folder"></i> {{ folder.name }}</p>
            <p class="mb-1">{{ folder.description }}</p>
          </a>
        </div>
      </div><!-- /.list-group -->
      <!-- DATASETS -->
      <div class="list-group sets" v-if="!loadingDatasets">
        <div
        v-for="(datasetItem, i) in $store.state.datasets"
        v-bind:key="i"
        class="list-container"
        >
          <a
            @click="launchDataset(datasetItem)"
            class="list-group-item list-group-item-action clickable handle mb-2"
          >
            <p class="lead mb-1"><i v-if="datasetItem.dynamic" class="fas fa-satellite-dish" v-b-tooltip.hover.top="'Dynamic Dataset'"></i> {{ datasetItem.name }}</p>
            <p class="mb-1">{{ datasetItem.description }}</p>
          </a>
        </div>
      </div><!-- /.list-group -->
    </div><!-- /.card -->
  </div><!-- /#dataset-picker -->
</template>

<script>
import axios from 'axios'
import jenks from 'turf-jenks'
const turf = require('@turf/turf')

export default {
  data () {
    return {
      loadingFolders: true,
      loadingDatasets: true,
      reloadSamplesDataset: false
    }
  },
  computed: {
    breadcrumbs: {
      get () {
        return this.$store.state.breadcrumbs
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'breadcrumbs', value: value })
      }
    },
    dataset: {
      get () {
        return this.$store.state.dataset
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'dataset', value: value })
      }
    },
    datasetGeoJson: {
      get () {
        return this.$store.state.datasetGeoJson
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'datasetGeoJson', value: value })
      }
    },
    mapObject: {
      get () {
        return this.$store.state.mapObject
      },
      set (value) {
        this.$store.commit('setMapObject', value)
      }
    },
    sampleDataset: {
      get () {
        return this.$store.state.sampleDataset
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'sampleDataset', value: value })
      }
    },
    sampleDatasetGeoJson: {
      get () {
        return this.$store.state.sampleDatasetGeoJson
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'sampleDatasetGeoJson', value: value })
      }
    }
  },
  props: [
    'title',
    'stateDoc',
    'stateGeoJson',
    'stateHeader'
  ],
  created () {
    this.fetchDatasets()
    this.fetchFolders()
  },
  methods: {
    fetchDatasets () {
      this.loadingDatasets = true
      const timeStamp = new Date().getTime()
      const datasetsQuery = 'https://api.efficientvineyard.com/datasets/' + this.$store.state.farm.id + '?timestamp=' + timeStamp + '&limit=100&folder=' + this.$store.state.folder.id
      axios.get(datasetsQuery).then(response => {
        this.$store.commit('setStateProperty', { property: 'datasets', value: response.data })
        this.loadingDatasets = false
      }).catch(err => {
        console.log(err.message)
      })
    },
    fetchFolders () {
      this.loadingFolders = true
      const timeStamp = new Date().getTime()
      const foldersQuery = 'https://api.efficientvineyard.com/folders/' + this.$store.state.farm.id + '?timestamp=' + timeStamp + '&limit=100&folder=' + this.$store.state.folder.id
      axios.get(foldersQuery).then(response => {
        this.$store.commit('setStateProperty', { property: 'folders', value: response.data })
        this.loadingFolders = false
      }).catch(err => {
        console.log(err.message)
      })
    },
    launchFolder (folder, fromCrumb) {
      if (this.breadcrumbs.length < 1) {
        this.breadcrumbs = [{ name: 'Root', id: 'root' }]
      }
      if (fromCrumb) {
        const newBreadcrumbs = []
        this.breadcrumbs.some(crumb => {
          if (crumb.id !== folder.id) {
            newBreadcrumbs.push(crumb)
          } else {
            newBreadcrumbs.push(crumb)
            return true
          }
        })
        this.breadcrumbs = newBreadcrumbs
      } else {
        this.breadcrumbs.push(folder)
      }
      this.$store.commit('setStateProperty', { property: 'folder', value: folder })
      this.fetchDatasets()
      this.fetchFolders()
    },
    launchDataset (datasetItem) {
      this.$store.commit('setStateProperty', { property: this.stateHeader, value: false })
      this.$store.commit('setStateProperty', { property: this.stateDoc, value: datasetItem })
      if (datasetItem.vizSettings) {
        this.$store.commit('setStateProperty', { property: this.stateHeader, value: datasetItem.vizSettings.colorHeader })
      }
      this.$store.commit('setStateProperty', { property: this.stateLoading, value: true })
      axios.get(datasetItem.file.geoJsonURL).then(response => {
        this.$store.commit('setStateProperty', { property: this.stateGeoJson, value: response.data })
        this.mapDataset()
      }).catch(err => {
        console.log(err)
        this.$store.commit('setStateProperty', { property: this.stateLoading, value: false })
      })
    },
    mapDataset (justSamples) {
      if (this.mapObject.getLayer(this.stateDoc + 'Preview') && !justSamples) {
        this.mapObject.removeLayer(this.stateDoc + 'Preview')
        this.mapObject.removeSource(this.stateDoc + 'Preview')
      }
      if (this.stateDoc === 'samplesDataset' || justSamples) {
        if (this.mapObject.getLayer('samplesDatasetPreviewOutline')) {
          this.mapObject.removeLayer('samplesDatasetPreviewOutline')
          this.mapObject.removeSource('samplesDatasetPreviewOutline')
        }
        if (this.mapObject.getLayer('samplesDatasetPreview')) {
          this.mapObject.removeLayer('samplesDatasetPreview')
          this.mapObject.removeSource('samplesDatasetPreview')
        }
        this.mapObject.addLayer({
          id: 'samplesDatasetPreviewOutline',
          type: 'circle',
          source: {
            type: 'geojson',
            data: this.$store.state.samplesDatasetGeoJson
          },
          layout: {},
          paint: {
            'circle-radius': 8,
            'circle-color': 'white'
          }
        })
        this.mapObject.addLayer({
          id: 'samplesDatasetPreview',
          type: 'circle',
          source: {
            type: 'geojson',
            data: this.$store.state.samplesDatasetGeoJson
          },
          layout: {},
          paint: {
            'circle-radius': 6,
            'circle-color': '#ffc107'
          }
        })
      } else {
        this.mapObject.addLayer({
          id: this.stateDoc + 'Preview',
          type: 'circle',
          source: {
            type: 'geojson',
            data: this.$store.state[this.stateDoc + 'GeoJson']
          },
          layout: {},
          paint: {
            'circle-radius': 6,
            'circle-color': '#ffc107'
          }
        })
      }
      if (!justSamples && this.$store.state.samplesDataset.name) {
        this.mapDataset(true)
      }
      this.$store.commit('setStateProperty', { property: this.stateLoading, value: false })
      if (this.stateDoc === 'dataset') {
        this.initViz()
      }
    },
    initViz () {
      setTimeout(() => {
        const colorHeader = this.dataset.vizSettings.colorHeader
        let radius
        let color
        this.dataset.vizSettings.featureCategories = []
        // What type of Viz
        if (this.dataset.vizSettings.type === 'circle') {
          this.mapObject.setLayoutProperty('datasetPreview', 'visibility', 'visible')
          // Color by setting
          if (this.dataset.vizSettings.colorBy === 'solid') {
            // Circle settings
            color = this.dataset.vizSettings.circleColor
            this.mapObject.setPaintProperty('datasetPreview', 'circle-color', color)
          }
          // Value Based settings assume that the header is numbers
          if (this.dataset.vizSettings.colorBy === 'valueBased') {
            // Range includes the min and max for numbers as well as categories
            this.dataset.vizSettings.minValue = false
            this.dataset.vizSettings.maxValue = false
            this.dataset.vizSettings.categorical = false
            this.dataset.vizSettings.number = true
            if (!this.dataset.vizSettings.colors) {
              this.dataset.vizSettings.colors = [
                '#77CCCC',
                '#777711',
                '#AA7744',
                '#AAAA44',
                '#774411',
                '#4477AA',
                '#AA4455',
                '#771155',
                '#DDDD77',
                '#117744',
                '#DDAA77',
                '#CC99BB',
                '#44AAAA',
                '#117777',
                '#77AADD',
                '#88CCAA',
                '#DD7788',
                '#114477',
                '#AA4488',
                '#771122',
                '#44AA77'
              ]
            }
            this.datasetGeoJson.features.forEach((feature, i) => {
              let featureValue = feature.properties[colorHeader]
              // If null, then make the value 0
              if (featureValue === null) {
                featureValue = 0
                this.datasetGeoJson.features[i].properties[colorHeader] = 0
              }
              // Check to see if the property can be considered a number
              const notNumber = isNaN(featureValue)
              if (notNumber) {
                // If any feature is not a number, set categorical to true
                this.dataset.vizSettings.categorical = true
                this.dataset.vizSettings.number = false
              } else {
                // It is a number, now check to see if the property is stored
                // as a string and if so, convert to number
                if (typeof featureValue === 'string') {
                  featureValue = Number(featureValue)
                  this.datasetGeoJson.features[i].properties[colorHeader] = featureValue
                }
                if (this.dataset.vizSettings.minValue === false) {
                  this.dataset.vizSettings.minValue = featureValue
                  this.dataset.vizSettings.maxValue = featureValue
                }
                if (featureValue < this.dataset.vizSettings.minValue) {
                  this.dataset.vizSettings.minValue = featureValue
                }
                if (featureValue > this.dataset.vizSettings.maxValue) {
                  this.dataset.vizSettings.maxValue = featureValue
                }
              }
            })
            // Check to see if we have a filter for the primary colorHeader
            if (this.dataset.vizSettings.filters.length > 0) {
              this.dataset.vizSettings.filters.forEach((filter, index) => {
                if (filter.header === this.dataset.vizSettings.colorHeader) {
                  this.dataset.vizSettings.minValue = this.dataset.vizSettings.filters[index].min
                  this.dataset.vizSettings.maxValue = this.dataset.vizSettings.filters[index].max
                }
              })
            }
            const categoryCounts = {}
            let categoryValue
            for (let i = 0; i < this.datasetGeoJson.features.length; i++) {
              categoryValue = this.datasetGeoJson.features[i].properties[colorHeader]
              if (typeof categoryCounts[categoryValue] === 'undefined') {
                categoryCounts[categoryValue] = 1
              } else {
                categoryCounts[categoryValue]++
              }
            }
            const entries = Object.entries(categoryCounts)
            entries.forEach((entry, i) => {
              if (i < 20) {
                this.dataset.vizSettings.featureCategories.push({
                  name: entry[0],
                  count: entry[1],
                  color: this.dataset.vizSettings.colors[i]
                })
              }
            })
            this.dataset.vizSettings.recalculateRange = false
            color = [
              'match',
              ['get', colorHeader]
            ]
            this.dataset.vizSettings.featureCategories.forEach((category, i) => {
              let name = category.name
              if (this.dataset.vizSettings.number) {
                name = Number(category.name)
              }
              color.push(name)
              color.push(this.dataset.vizSettings.colors[i])
            })
            color.push('#ccc')
            // ZONE SETTINGS
            // LINEAR
            if (this.dataset.vizSettings.zoneClassification === 'linear' && !this.dataset.vizSettings.categorical) {
              color = [
                'interpolate',
                ['linear'],
                ['to-number', ['get', colorHeader]],
                this.dataset.vizSettings.minValue,
                this.dataset.vizSettings.minColor,
                this.dataset.vizSettings.maxValue,
                this.dataset.vizSettings.maxColor
              ]
            }
            // EQUAL INTERVAL
            if (this.dataset.vizSettings.zoneClassification === 'equal-interval' && !this.dataset.vizSettings.categorical) {
              color = [
                'step',
                ['to-number', ['get', colorHeader]],
                'black',
                this.dataset.vizSettings.minValue, this.dataset.vizSettings.colors[0]
              ]
              const stopDiff = this.dataset.vizSettings.maxValue - this.dataset.vizSettings.minValue
              const stopLength = 1 / this.dataset.vizSettings.zoneStops
              for (let i = 1; i <= this.dataset.vizSettings.zoneStops; i++) {
                const stopMultiplier = stopLength * i
                const stopFormula = (stopDiff * stopMultiplier) + this.dataset.vizSettings.minValue
                color.push(stopFormula)
                color.push(this.dataset.vizSettings.colors[i])
              }
            }
            // JENKS
            if (this.dataset.vizSettings.zoneClassification === 'jenks' && !this.dataset.vizSettings.categorical) {
              if (this.dataset.vizSettings.recalculateJenks) {
                this.dataset.vizSettings.breaks = jenks(this.datasetGeoJson, colorHeader, this.dataset.vizSettings.zoneStops)
              }
              if (this.dataset.vizSettings.breaks[0] === undefined) {
                this.dataset.vizSettings.breaks[0] = this.dataset.vizSettings.minValue
              }
              this.dataset.vizSettings.breaks.forEach((breakItem, i) => {
                if (typeof breakItem === 'string') {
                  this.dataset.vizSettings.breaks[i] = Number(this.dataset.vizSettings.breaks[i])
                }
              })
              color = [
                'step',
                ['to-number', ['get', colorHeader]],
                'black'
              ]
              this.dataset.vizSettings.breaks.forEach((breakItem, i) => {
                color.push(this.dataset.vizSettings.breaks[i])
                color.push(this.dataset.vizSettings.colors[i])
              })
            }
            // CATEGORICAL NUMBERS
            if (this.dataset.vizSettings.zoneClassification === 'categorical') {
              this.dataset.vizSettings.categorical = true
            }
            // }
          }
          // Check for sizing options
          if (this.dataset.vizSettings.sizeBy === 'fixed') {
            radius = this.dataset.vizSettings.circleRadius
          } else {
            this.dataset.vizSettings.minSizeValue = false
            this.dataset.vizSettings.maxSizeValue = false
            this.dataset.vizSettings.sizeCategorical = false
            this.dataset.vizSettings.sizeNumber = true
            // loop through and get min/max size values
            const sizeHeader = this.dataset.vizSettings.sizeHeader
            this.datasetGeoJson.features.forEach((feature, i) => {
              let featureSizeValue = feature.properties[sizeHeader]
              // Check to see if the property can be considered a number
              const notNumber = isNaN(featureSizeValue)
              if (notNumber) {
                // If any feature is not a number, set categorical to true
                this.dataset.vizSettings.sizeCategorical = true
                this.dataset.vizSettings.sizeNumber = false
              } else {
                // It is a number, now check to see if the property is stored
                // as a string and if so, convert to number
                if (typeof featureSizeValue === 'string') {
                  featureSizeValue = Number(featureSizeValue)
                  this.datasetGeoJson.features[i].properties[sizeHeader] = featureSizeValue
                }
                if (!this.dataset.vizSettings.minSizeValue) {
                  this.dataset.vizSettings.minSizeValue = featureSizeValue
                  this.dataset.vizSettings.maxSizeValue = featureSizeValue
                }
                if (featureSizeValue < this.dataset.vizSettings.minSizeValue) {
                  this.dataset.vizSettings.minSizeValue = featureSizeValue
                }
                if (featureSizeValue > this.dataset.vizSettings.maxSizeValue) {
                  this.dataset.vizSettings.maxSizeValue = featureSizeValue
                }
              }
            })
            if (this.dataset.vizSettings.sizeNumber) {
              // Set up the radius setting
              radius = [
                'interpolate',
                ['linear'],
                ['to-number', ['get', this.dataset.vizSettings.sizeHeader]],
                this.dataset.vizSettings.minSizeValue, // Min Value
                this.dataset.vizSettings.circleRadiusMin, // Min Render
                this.dataset.vizSettings.maxSizeValue, // Max Value
                this.dataset.vizSettings.circleRadiusMax // Max Render
              ]
            } else {
              radius = this.dataset.vizSettings.circleRadius
            }
          }
          if (this.dataset.vizSettings.filters.length > 0) {
            // Filter Dataset Map with filters
            const filters = ['all']
            this.dataset.vizSettings.filters.forEach(filter => {
              if (filter.header) {
                filters.push(['>=', ['number', ['get', filter.header]], filter.min])
                filters.push(['<=', ['number', ['get', filter.header]], filter.max])
              }
            })
            this.mapObject.setFilter('datasetPreview', filters)
          } else {
            this.mapObject.setFilter('datasetPreview', undefined)
          }
          this.mapObject.setPaintProperty('datasetPreview', 'circle-radius', radius)
          this.mapObject.setPaintProperty('datasetPreview', 'circle-color', color)
        }
        const datasetBounds = turf.bbox(this.datasetGeoJson)
        this.mapObject.fitBounds(datasetBounds, {
          padding: 50,
          animate: true
        })
        this.$store.commit('setStateProperty', { property: 'loadingDataset', value: false })
      }, 1000) // timeout
    }
  }
}
</script>

<style scoped>
.card > .list-group {
  border-top: none;
  border-bottom: none;
}
.folder {
  background-color: #eee;
}

.badge.clickable:hover {
  box-shadow: 1px 2px 4px rgba(0,0,0,0.5);
}
</style>
