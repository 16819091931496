<template>
  <div id="home">
    <Options />
    <TranslationMap />
    <Menu />
  </div>
</template>

<script>
import Options from './Options'
import TranslationMap from './TranslationMap'
import Menu from './Menu'

export default {
  components: {
    Options,
    TranslationMap,
    Menu
  },
  created () {
    this.$store.dispatch('initPlugin', this.$route.params.farmId)
  }
}
</script>
