<template>
  <div id="app">
    <router-view />
    <div
      v-if="$store.state.spinner"
      class="spinner-container"
    >
      <RingLoader
        :color="'#61539b'"
        id="spinner"
      ></RingLoader>
    </div>
    <Alert v-if="$store.state.alert.active" />
  </div>
</template>

<script>
import RingLoader from 'vue-spinner/src/GridLoader'
import Alert from './components/Alert'

export default {
  components: {
    RingLoader,
    Alert
  }
}
</script>

<style scoped>
.spinner-container {
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.v-spinner {
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50vh - 50px);
}
</style>
